import moment from "moment-timezone";

export default {
  formatDate(data, invalid = null) {
    if (!data) {
      return invalid;
    }

    return moment(data)
      .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
      .format("DD-MM-YYYY");
  },

  formatDateReverse(data, invalid = null) {
    if (!data) {
      return invalid;
    }

    return moment(data)
      .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
      .format("YYYY-MM-DD");
  },

  formatDateTimeReverse(data, invalid = null) {
    if (!data) {
      return invalid;
    }

    return moment(data)
      .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
      .format("YYYY-MM-DD HH:mm");
  },

  formatDateTime(data, invalid = null) {
    if (!data) {
      return invalid;
    }

    return moment(data)
      .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
      .format("DD-MM-YYYY HH:mm:ss");
  },
};
