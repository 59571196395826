<template>
  <div class="dashboard-margin">
    <PrimeCard class="user-card">
      <template #content>
        <div>
          <div class="col-12 p-0">
            <div class="grid">
              <div class="col-4">
                <InputTextRequire
                  v-model="vmodel.paymentmethod"
                  :validation="v$.vmodel.paymentmethod"
                  sectionClass="field"
                  id="paymentmethod"
                  label="Payment Method"
                  placeholder="Payment Method"
                  maxlength="50"
                  @keypress="onlyCharacter"
                />
              </div>
            </div>
          </div>
          <div class="mt-2">
            <PrimeButton
              label="Add"
              class="order-form-button"
              v-if="!isEditMode"
              @click="createPayment"
            ></PrimeButton>
            <PrimeButton
              label="Save"
              class="order-edit-form-button"
              v-else
              @click="createPayment"
            ></PrimeButton>
          </div>
        </div>
      </template>
    </PrimeCard>
  </div>
</template>
<script>
import APIService from "@/services/api-service.js";
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength } from "@vuelidate/validators";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      submitted: false,
      isEditMode: false,
      vmodel: {
        paymentmethod: "",
      },
    };
  },

  mounted() {
    if (this.$route.params.id) {
      this.isEditMode = true;
      this.getPaymentById();
    }
  },
  validations() {
    return {
      vmodel: {
        paymentmethod: { required, max: maxLength(30) },
      },
    };
  },
  methods: {
    onlyCharacter($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (
        (keyCode < 65 || keyCode > 90) &&
        (keyCode < 97 || keyCode > 123) &&
        keyCode != 32
      ) {
        $event.preventDefault();
      }
    },
    async createPayment() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        if (!this.vmodel.paymentmethod.match(/^[A-Za-z\s]+$/)) {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Payment Method only allow alphabetic characters.",
            life: 3000,
          });
          return;
        }
        let payload = {
          paymentmethod: this.vmodel.paymentmethod,
        };
        this.submitted = true;
        if (this.$route.params.id) {
          APIService.put("/payment", payload, this.$route.params.id)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "PaymentMethod" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        } else {
          APIService.post("/payment/add", payload)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "PaymentMethod" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        }
      }
    },
    async getPaymentById() {
      APIService.getById("/payment", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.paymentmethod = response.data.data.paymentmethod;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "PaymentMethod" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.invalid {
  border-color: #dc3545 !important;
  border: 1px solid #dc3545 !important;
}
::v-deep(.p-card .p-card-body) {
  padding: 40px 40px 40px 40px;
}
::v-deep(.user-card.p-card) {
  height: 100% !important;
  min-height: 86vh !important;
}
</style>
