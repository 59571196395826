import DateFormat from "@/libs/DateFormat";

export default class NewsManagement {
  constructor(data = {}) {
    return {
      id: data.id || "",
      newsid: data.newsid || "",
      title: data.title || "",
      content: data.content || "",
      createddate: DateFormat.formatDate(data.createddate) || "",
    };
  }
}
