<template>
  <div class="dashboard-margin">
    <PrimeCard class="paymentlist-card">
      <template #content>
        <DataTable
          :value="paymentmethod"
          :paginator="true"
          :lazy="true"
          class="p-datatable-lg p-datatable-customers"
          :rows="itemsPerPage"
          :totalRecords="totalRecords"
          dataKey="id"
          :rowHover="true"
          :resizableColumns="true"
          @page="onPage($event)"
          @sort="onSort($event)"
          paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} results"
        >
          <template #header>
            <PrimeButton
              label="ADD"
              class="add-button"
              icon="plus-icon"
              @click="gotoCreatePayment"
            ></PrimeButton>
          </template>
          <template #loading>Loading records, please wait...</template>
          <template #empty>No records found.</template>
          <Column field="paymentmethod" header="Payment Method" sortable></Column>
          <Column field="action" header="Action">
            <template #body="data">
              <div class="action-cloumn">
                <PrimeButton
                  label="Edit"
                  class="edit-button"
                  @click="gotoPaymentEdit(data.data.id)"
                />
                <PrimeButton
                  label="Delete"
                  class="delete-button"
                  @click="showDeletePopup(data.data.id)"
                />
              </div>
            </template>
          </Column>
        </DataTable>
        <div>
          <ConfirmPopup
            :show-popup="showPopup"
            :message="message"
            :header="header"
            @confirmYes="deletePayment"
            @confirmNo="showPopup = false"
          ></ConfirmPopup>
        </div>
      </template>
    </PrimeCard>
  </div>
</template>
<script>
import APIService from "@/services/api-service.js";
import PaymentMethodFactory from "@/factories/paymentmethod";
export default {
  data() {
    return {
      currentPage: 0,
      itemsPerPage: 10,
      totalRecords: 0,
      sortColumn: "id",
      sortDir: "asc",
      paymentmethod: [],
      message: "",
      header: "",
      paymentIdForDelete: "",
      showPopup: false,
    };
  },
  mounted() {
    this.getPaymentList();
  },
  methods: {
    gotoCreatePayment() {
      this.$router.push({ name: "PaymentMethodCreate" });
    },
    gotoPaymentEdit(paymentId) {
      this.$router.push({
        name: "PaymentMethodEdit",
        params: { id: paymentId },
      });
    },
    showDeletePopup(paymentId) {
      this.paymentIdForDelete = paymentId;
      this.message =
        "Are you sure you want to delete the payment? <br/> Once you confirm this action, the account will be <br/> deleted immediately. You can't undo this action.";
      this.header = "Delete Payment";
      this.showPopup = true;
    },
    getPaymentList() {
      this.paymentmethod = [];
      APIService.get(
        `/payment/list/${this.currentPage}/${this.itemsPerPage}/${this.sortColumn}/${this.sortDir}`
      ).then((response) => {
        if (response && response.data) {
          this.totalRecords = response.data.totalrecord;
          this.paymentmethod = PaymentMethodFactory.createFromJsonArray(
            response.data.data
          );
        }
      });
    },
    deletePayment() {
      APIService.delete(`/payment/delete/${this.paymentIdForDelete}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.getPaymentList();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.showPopup = false;
        });
    },
    onPage(pageData) {
      this.currentPage = pageData.first;
      this.getPaymentList();
    },
    onSort(sortData) {
      let sortField = sortData.sortField;
      if (sortData.sortField === "createddate") {
        sortField = "createddate";
      }
      this.sortColumn = sortField;
      this.sortDir = sortData.sortOrder === 1 ? "asc" : "dsc";
      this.currentPage = 0;

      this.getPaymentList();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/datatable.scss";
::v-deep(.p-card .p-card-body) {
  padding: 40px 20px auto 20px;
}
::v-deep(.paymentlist-card.p-card) {
  height: 100% !important;
  min-height: 86vh !important;
}
.margin-title {
  max-width: 357px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.margin-content {
  max-width: 545px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
