<template>
  <div class="dashboard-margin">
    <PrimeCard class="reservationlist-card">
      <template #content>
        <DataTable
          :value="reservationrecords"
          :paginator="true"
          :lazy="true"
          class="p-datatable-lg p-datatable-customers"
          :rows="itemsPerPage"
          :totalRecords="totalRecords"
          dataKey="id"
          :rowHover="true"
          :resizableColumns="true"
          @page="onPage($event)"
          @sort="onSort($event)"
          paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} results"
        >
          <template #loading>Loading records, please wait...</template>
          <template #empty>No records found.</template>
          <Column
            field="reservationno"
            header="Reservation No."
            sortable
          ></Column>
          <Column field="username" header="User name" sortable></Column>
          <Column
            field="reservationtype"
            header="Reservation Service"
            sortable
          ></Column>
          <Column
            field="carmodeltype"
            header="Car type & model"
            sortable
          ></Column>
          <Column field="status" header="Status" sortable>
            <template #body="row">
              <div class="flex">
                <span class="capitalize">{{ row.data.status }}</span>
              </div>
            </template>
          </Column>
          <Column field="action" header="Action">
            <template #body="data">
              <div class="action-cloumn">
                <PrimeButton
                  label="View"
                  class="edit-button"
                  @click="gotoRecordView(data.data.id)"
                />
              </div>
            </template>
          </Column>
        </DataTable>
      </template>
    </PrimeCard>
  </div>
  <div>
    <BookingPopup
      :show-popup="showPopup"
      :record-id="recordIdForView"
      @displayNo="closePopup()"
      @updateSuccess="successupdatePopup()"
    ></BookingPopup>
  </div>
</template>
<script>
import APIService from "@/services/api-service.js";
import ReservationFactory from "@/factories/reservationrecord.js";
export default {
  data() {
    return {
      showPopup: false,
      currentPage: 0,
      itemsPerPage: 10,
      totalRecords: 0,
      sortColumn: "id",
      sortDir: "asc",
      reservationrecords: [],
      recordIdForView: "",
    };
  },
  mounted() {
    this.getReservationRecordsList();
  },
  methods: {
    closePopup() {
      this.showPopup = false;
      this.recordIdForView = "";
    },
    successupdatePopup() {
      this.showPopup = false;
      this.recordIdForView = "";
      this.getReservationRecordsList();
    },
    gotoRecordView(recordId) {
      this.recordIdForView = recordId;
      this.showPopup = true;
    },
    getReservationRecordsList() {
      this.reservationrecords = [];
      APIService.get(
        `/reservation/getalllist/${this.currentPage}/${this.itemsPerPage}/${this.sortColumn}/${this.sortDir}`
      ).then((response) => {
        if (response && response.data) {
          this.totalRecords = response.data.totalrecords;
          this.reservationrecords = ReservationFactory.createFromJsonArray(
            response.data.data
          );
        }
      });
    },
    onPage(pageData) {
      this.currentPage = pageData.first;
      this.getReservationRecordsList();
    },
    onSort(sortData) {
      let sortField = sortData.sortField;
      if (sortData.sortField === "createddate") {
        sortField = "createddate";
      }
      this.sortColumn = sortField;
      this.sortDir = sortData.sortOrder === 1 ? "asc" : "dsc";
      this.currentPage = 0;

      this.getReservationRecordsList();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/datatable.scss";
::v-deep(.p-card .p-card-body) {
  padding: 20px 20px auto 20px;
}
::v-deep(.reservationlist-card.p-card) {
  height: 100% !important;
  min-height: 86vh !important;
}
.capitalize{
  text-transform: capitalize;
}
</style>
