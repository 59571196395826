<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="dashboard-margin">
    <PrimeCard class="profile-card">
      <template #content>
        <div class="col-12 p-0">
          <div class="grid m-0">
            <div class="col-4 p-0">
              <label for="password" class="form-label float-left">
                <span class="validation-point">*</span> Current Password</label
              >
              <InputText
                id="currentpassword"
                placeholder="Current Password"
                type="password"
                class="w-full password-input"
                v-model="vmodel.current_password"
                maxlength="12"
                minlength="8"
                :class="{
                  'p-invalid': v$.vmodel.current_password.$error,
                }"
                aria-describedby="current_password-help"
                @keypress="notAllowSpace"
              />
            </div>
          </div>
          <div class="grid">
            <div class="col-12 py-0">
              <span
                v-if="
                  v$.vmodel.current_password.$error &&
                  v$.vmodel.current_password.required.$invalid
                "
                id="current_password-help"
                class="p-error text-left mt-2"
              >
                {{
                  v$.vmodel.current_password.required.$message.replace(
                    "Value",
                    "Current Password"
                  )
                }}
              </span>
              <span
                v-else-if="
                  v$.vmodel.current_password.$error &&
                  v$.vmodel.current_password.min.$invalid
                "
                id="current_password-help"
                class="p-error mt-2"
              >
                {{ v$.vmodel.current_password.min.$message }}
              </span>
              <span
                v-else-if="
                  v$.vmodel.current_password.$error &&
                  v$.vmodel.current_password.max.$invalid
                "
                id="current_password-help"
                class="p-error mt-2"
              >
                {{ v$.vmodel.current_password.max.$message }}
              </span>
            </div>
          </div>
          <div class="grid mt-3">
            <div class="col-4">
              <label for="password" class="form-label float-left">
                <span class="validation-point">*</span> New Password</label
              >
              <InputText
                id="newpassword"
                placeholder="New Password"
                type="password"
                class="w-full password-input"
                v-model="vmodel.new_password"
                maxlength="12"
                minlength="8"
                :class="{
                  'p-invalid': v$.vmodel.new_password.$error,
                }"
                aria-describedby="new_password-help"
                @keypress="notAllowSpace"
              />
            </div>
          </div>
          <div class="grid">
            <div class="col-12 py-0">
              <span
                v-if="
                  v$.vmodel.new_password.$error &&
                  v$.vmodel.new_password.required.$invalid
                "
                id="new_password-help"
                class="p-error text-left mt-2"
              >
                {{
                  v$.vmodel.new_password.required.$message.replace(
                    "Value",
                    "New Password"
                  )
                }}
              </span>
              <span
                v-else-if="
                  v$.vmodel.new_password.$error &&
                  v$.vmodel.new_password.min.$invalid
                "
                id="new_password-help"
                class="p-error mt-2"
              >
                {{ v$.vmodel.new_password.min.$message }}
              </span>
              <span
                v-else-if="
                  v$.vmodel.new_password.$error &&
                  v$.vmodel.new_password.max.$invalid
                "
                id="new_password-help"
                class="p-error mt-2"
              >
                {{ v$.vmodel.new_password.max.$message }}
              </span>
            </div>
          </div>
          <div class="grid mt-3">
            <div class="col-4">
              <label for="password" class="form-label float-left">
                <span class="validation-point">*</span> Confirm Password</label
              >
              <InputText
                id="confirmpassword"
                placeholder="Confirm Password"
                type="password"
                class="w-full password-input"
                v-model="vmodel.confirm_password"
                maxlength="12"
                minlength="8"
                :class="{
                  'p-invalid': v$.vmodel.confirm_password.$error,
                }"
                aria-describedby="confirm_password-help"
                @keypress="notAllowSpace"
              />
            </div>
          </div>
          <div class="grid">
            <div class="col-12 py-0">
              <span
                v-if="
                  v$.vmodel.confirm_password.$error &&
                  v$.vmodel.confirm_password.required.$invalid
                "
                id="confirm_password-help"
                class="p-error text-left mt-2"
              >
                {{
                  v$.vmodel.confirm_password.required.$message.replace(
                    "Value",
                    "Confirm Password"
                  )
                }}
              </span>
              <span
                v-else-if="
                  v$.vmodel.confirm_password.$error &&
                  v$.vmodel.confirm_password.min.$invalid
                "
                id="confirm_password-help"
                class="p-error mt-2"
              >
                {{ v$.vmodel.confirm_password.min.$message }}
              </span>
              <span
                v-else-if="
                  v$.vmodel.confirm_password.$error &&
                  v$.vmodel.confirm_password.max.$invalid
                "
                id="confirm_password-help"
                class="p-error mt-2"
              >
                {{ v$.vmodel.confirm_password.max.$message }}
              </span>
              <span
                v-else-if="
                  v$.vmodel.confirm_password.$error &&
                  v$.vmodel.confirm_password.sameAs.$invalid
                "
                id="confirm_password-help"
                class="p-error text-left mt-2"
              >
                New Password and Confirm Password must be match
              </span>
            </div>
          </div>
          <div class="mt-4">
            <PrimeButton
              label="Save"
              class="order-edit-form-button"
              @click="changePassword"
            ></PrimeButton>
          </div>
        </div>
      </template>
    </PrimeCard>
  </div>
</template>
<script>
import APIService from "@/services/api-service.js";
import getPassword from "@/libs/password";
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength, minLength, sameAs } from "@vuelidate/validators";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      visibleDDMenu: false,
      currentPage: 0,
      itemsPerPage: 10,
      search: "",
      totalRecords: 0,
      sortColumn: "id",
      sortDir: "asc",
      userIdForDelete: "",
      showPopup: false,
      vmodel: {
        current_password: null,
        new_password: null,
        confirm_password: null,
      },
    };
  },
  validations() {
    return {
      vmodel: {
        current_password: { required, min: minLength(8), max: maxLength(12) },
        new_password: { required, min: minLength(8), max: maxLength(12) },
        confirm_password: {
          required,
          min: minLength(8),
          max: maxLength(12),
          sameAs: sameAs(this.vmodel.new_password),
        },
      },
    };
  },
  methods: {
    notAllowSpace($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode == 32) {
        $event.preventDefault();
      }
    },
    async changePassword() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        let currentPassword = getPassword.generatePassword(
          this.vmodel.current_password
        );
        let encodedCurrentPassword = encodeURIComponent(currentPassword);
        let newPassword = getPassword.generatePassword(
          this.vmodel.new_password
        );
        let encodedNewPassword = encodeURIComponent(newPassword);
        let payload = {
          password: encodedCurrentPassword,
          newpassword: encodedNewPassword,
        };
        APIService.put("/admin/changepassword", payload)
          .then((response) => {
            if (response && response.data && response.data.success) {
              this.$toast.add({
                severity: "success",
                summary: "Success",
                detail: response.data.message,
                life: 3000,
              });
              setTimeout(() => {
                this.$router.push({ name: "ProfileSetting" });
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.data.message,
                life: 3000,
              });
            }
          })
          .finally(() => {
            this.submitted = false;
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/datatable.scss";
::v-deep(.p-card) {
  min-height: 0;
}
::v-deep(.p-card .p-card-body) {
  padding: 40px;
}
.profile-setting-label {
  height: 24px;
  font-family: NunitoSans;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  text-align: right;
  color: #86909c;
}
.profile-email-label {
  margin-left: 18px;
  height: 24px;
  line-height: 24px;
  font-family: NunitoSans;
  font-size: 16px;
  font-style: normal;
  color: #1d2129;
}
.p-inputtext.password-input {
  height: 34px;
  font-family: NunitoSans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #1d2129;
  font-style: normal;
  padding: 5px 12px;
  background: #f2f3f5;
  border: none;
  border-radius: 2px;
}
.p-inputtext.password-input:hover {
  border: none !important;
}
.p-inputtext.password-input.p-invalid {
  border: 1px solid #dc3545 !important;
}
::v-deep(.profile-card.p-card) {
  height: 100% !important;
  min-height: 86vh !important;
}
.validation-point {
  color: #f53f3f;
}
</style>
