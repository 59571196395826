import CryptoJS from "crypto-js";
var jwtToken = process.env.VUE_APP_JWT_TOKEN_SECRET;
export default {
  generatePassword(data) {
    return CryptoJS.AES.encrypt(data, jwtToken).toString();
  },

  getPassword(data) {
    return CryptoJS.AES.decrypt(data, jwtToken).toString(CryptoJS.enc.Utf8);
  },
};
