<template>
  <div class="dashboard-margin">
    <PrimeCard class="memberlist-card">
      <template #content>
        <DataTable
          :value="members"
          :paginator="true"
          :lazy="true"
          class="p-datatable-lg p-datatable-customers"
          :rows="itemsPerPage"
          :totalRecords="totalRecords"
          dataKey="id"
          :rowHover="true"
          :resizableColumns="true"
          @page="onPage($event)"
          @sort="onSort($event)"
          paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} results"
        >
          <template #header>
            <PrimeButton
              label="ADD"
              class="add-button"
              icon="plus-icon"
              @click="gotoCreateMember"
            ></PrimeButton>
          </template>
          <template #loading>Loading records, please wait...</template>
          <template #empty>No records found.</template>
          <Column field="userid" header="User ID" sortable></Column>
          <Column field="username" header="User Name" sortable></Column>
          <Column field="phone" header="Phone Number" sortable></Column>
          <Column field="email" header="Email" sortable></Column>
          <Column field="action" header="Action">
            <template #body="data">
              <div class="action-cloumn">
                <PrimeButton
                  label="Edit"
                  class="edit-button"
                  @click="gotoMemberEdit(data.data.id)"
                />
                <PrimeButton
                  label="Delete"
                  class="delete-button"
                  @click="showDeletePopup(data.data.id)"
                />
              </div>
            </template>
          </Column>
        </DataTable>
        <div>
          <ConfirmPopup
            :show-popup="showPopup"
            :message="message"
            :header="header"
            @confirmYes="deleteMember"
            @confirmNo="showPopup = false"
          >
          </ConfirmPopup>
        </div>
      </template>
    </PrimeCard>
  </div>
</template>
<script>
import APIService from "@/services/api-service.js";
import MemberFactory from "@/factories/member";
export default {
  data() {
    return {
      currentPage: 0,
      itemsPerPage: 10,
      totalRecords: 0,
      sortColumn: "id",
      sortDir: "asc",
      showPopup: false,
      memberIdForDelete: "",
      message: "",
      header: "",
      members: [],
    };
  },
  mounted() {
    this.getMembersList();
  },
  methods: {
    gotoCreateMember() {
      this.$router.push({ name: "MemberManagementCreate" });
    },
    gotoMemberEdit(memberId) {
      this.$router.push({
        name: "MemberManagementEdit",
        params: { id: memberId },
      });
    },
    showDeletePopup(memberId) {
      this.memberIdForDelete = memberId;
      this.message =
        "Are you sure you want to delete the account? <br/> Once you confirm this action, the account will be <br/> deleted immediately. You can't undo this action.";
      this.header = "Delete Account";
      this.showPopup = true;
    },
    deleteMember() {
      APIService.delete(`/membermanagement/member/${this.memberIdForDelete}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.getMembersList();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.showPopup = false;
        });
    },
    getMembersList() {
      this.members = [];
      APIService.get(
        `/membermanagement/allmembers/${this.currentPage}/${this.itemsPerPage}/${this.sortColumn}/${this.sortDir}`
      ).then((response) => {
        if (response && response.data) {
          this.totalRecords = response.data.totalrecords;
          this.members = MemberFactory.createFromJsonArray(response.data.data);
        }
      });
    },
    onPage(pageData) {
      this.currentPage = pageData.first;
      this.getMembersList();
    },
    onSort(sortData) {
      let sortField = sortData.sortField;
      if (sortData.sortField === "createddate") {
        sortField = "createddate";
      }
      this.sortColumn = sortField;
      this.sortDir = sortData.sortOrder === 1 ? "asc" : "dsc";
      this.currentPage = 0;

      this.getMembersList();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/datatable.scss";
::v-deep(.p-card .p-card-body) {
  padding: 40px 20px auto 20px;
}
::v-deep(.memberlist-card.p-card) {
  height: 100% !important;
  min-height: 86vh !important;
}
</style>
