<template>
  <div>
    <aside>
      <div class="sidebar">
        <div class="side-logo">
          <img src="../../assets/icons/logo.png" alt="" />
        </div>
        <div class="menu mt-1">
          <router-link
            :to="{ name: 'MemberManagement' }"
            id="membermanagement"
            :class="
              $store.state.activeRoute === 'MemberManagement'
                ? 'menu-item selected'
                : 'menu-item'
            "
          >
            <i
              :class="
                $store.state.activeRoute === 'MemberManagement'
                  ? 'member-selected-icon selected'
                  : 'member-icon'
              "
            ></i>
            <span class="menu-text">Member Management</span>
          </router-link>
          <router-link
            :to="{ name: 'NewsManagement' }"
            id="newsmanagement"
            :class="
              $store.state.activeRoute === 'NewsManagement'
                ? 'menu-item selected'
                : 'menu-item'
            "
          >
            <i
              :class="
                $store.state.activeRoute === 'NewsManagement'
                  ? 'news-selected-icon selected'
                  : 'news-icon'
              "
            ></i>
            <span class="menu-text">News Management</span>
          </router-link>
          <router-link
            :to="{ name: 'CarManagement' }"
            id="carmanagement"
            :class="
              $store.state.activeRoute === 'CarManagement'
                ? 'menu-item selected'
                : 'menu-item'
            "
          >
            <i
              :class="
                $store.state.activeRoute === 'CarManagement'
                  ? 'car-selected-icon selected'
                  : 'car-icon'
              "
            ></i>
            <span class="menu-text">Car Management</span>
          </router-link>
          <router-link
            :to="{ name: 'ReservationRecord' }"
            id="reservationrecord"
            :class="
              $store.state.activeRoute === 'ReservationRecord'
                ? 'menu-item selected'
                : 'menu-item'
            "
          >
            <i
              :class="
                $store.state.activeRoute === 'ReservationRecord'
                  ? 'reservation-selected-icon selected'
                  : 'reservation-icon'
              "
            ></i>
            <span class="menu-text">Reservation Record</span>
          </router-link>
          <router-link
            :to="{ name: 'PaymentMethod' }"
            id="paymentmethod"
            :class="
              $store.state.activeRoute === 'PaymentMethod'
                ? 'menu-item selected'
                : 'menu-item'
            "
          >
            <i
              :class="
                $store.state.activeRoute === 'PaymentMethod'
                  ? 'payment-selected-icon selected'
                  : 'payment-icon'
              "
            ></i>
            <span class="menu-text">Payment Method</span>
          </router-link>
          <div
            class="menu-item cursor-pointer"
            @click="visibleDDMenu = !visibleDDMenu"
          >
            <i class="cmspage-icon"></i>
            <span class="menu-text">CMS Page Management</span>
            <i
              :class="{
                'down-icon': !visibleDDMenu,
                'up-icon': visibleDDMenu,
              }"
            ></i>
          </div>
          <router-link
            v-if="visibleDDMenu"
            :to="{ name: 'AboutUs' }"
            id="aboutus"
            :class="
              $store.state.activeRoute === 'AboutUs'
                ? 'menu-sub-item selected'
                : 'menu-sub-item'
            "
          >
            <span class="menu-text">About Us</span>
          </router-link>
          <router-link
            v-if="visibleDDMenu"
            :to="{ name: 'ContactUs' }"
            id="contactus"
            :class="
              $store.state.activeRoute === 'ContactUs'
                ? 'menu-sub-item selected'
                : 'menu-sub-item'
            "
          >
            <span class="menu-text">Contact Us</span>
          </router-link>
          <router-link
            v-if="visibleDDMenu"
            :to="{ name: 'PrivacyPolicy' }"
            id="privacypolicy"
            :class="
              $store.state.activeRoute === 'PrivacyPolicy'
                ? 'menu-sub-item selected'
                : 'menu-sub-item'
            "
          >
            <span class="menu-text">Privacy Policy</span>
          </router-link>
          <router-link
            v-if="visibleDDMenu"
            :to="{ name: 'TermsAndConditions' }"
            id="termsandconditions"
            :class="
              $store.state.activeRoute === 'TermsAndConditions'
                ? 'menu-sub-item selected'
                : 'menu-sub-item'
            "
          >
            <span class="menu-text">Terms & Conditions</span>
          </router-link>
          <router-link
            :to="{ name: 'ProfileSetting' }"
            id="profile"
            :class="
              $store.state.activeRoute === 'ProfileSetting'
                ? 'menu-item selected'
                : 'menu-item'
            "
          >
            <i
              :class="
                $store.state.activeRoute === 'ProfileSetting'
                  ? 'profile-selected-icon selected'
                  : 'profile-icon'
              "
            ></i>
            <span class="menu-text">Profile Setting</span>
          </router-link>
          <div
            class="menu-item cursor-pointer"
            @click="logout"
          >
            <i class="logout-icon"></i>
            <span class="menu-text">Logout</span>
          </div>
        </div>
        <div class="bottom-item">
          <span class="menu-text bottom-text">Top Apex Limited</span>
        </div>
      </div>
    </aside>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visibleDDMenu: false,
    };
  },
  methods: {
    hide() {
      this.visibleDDMenu = false;
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/sidebar.scss";
</style>
