<template>
  <div class="dashboard-margin">
    <PrimeCard class="user-card">
      <template #content>
        <div class="col-12">
          <div class="grid">
            <div class="col-4 flex flex-column">
              <InputTextRequire
                v-model="vmodel.cartype"
                :validation="v$.vmodel.cartype"
                sectionClass="field"
                id="cartype"
                label="Car type & model"
                placeholder="Car type & model"
                maxlength="50"
                class="set-width"
              />
            </div>
          </div>
          <div class="grid mt-2 mx-0 mb-0">
            <div class="col-12 flex px-0 pb-0" style="gap: 60px">
              <div class="flex flex-column col-4 width-column px-0 pb-0">
                <label for="photo" class="form-label service-tag"
                  >Airport Transfer</label
                >
              </div>
              <div class="flex flex-column col-4 width-column">
                <label for="photo" class="form-label service-tag"
                  >Local Transfer</label
                >
              </div>
              <div class="flex flex-column col-4 width-column">
                <label for="photo" class="form-label service-tag"
                  >Hourly On-Hire Limousine Services</label
                >
              </div>
            </div>
          </div>
          <div class="grid my-0">
            <div class="col-12 flex py-0" style="gap: 60px">
              <div class="flex flex-column col-4 width-column">
                <GroupInputNumber
                  v-model="vmodel.airporttransfer.hk"
                  :validation="v$.vmodel.airporttransfer.hk"
                  sectionClass="field"
                  id="hk"
                  label="Service charges of Hong Kong (From/To)"
                  labelHighLight="Hong Kong"
                  placeholder=""
                  :min="0"
                />
                <GroupInputNumber
                  v-model="vmodel.airporttransfer.kl"
                  :validation="v$.vmodel.airporttransfer.kl"
                  sectionClass="field mt-2"
                  id="kl"
                  label="Service charges of Kowloon (From/To)"
                  labelHighLight="Kowloon"
                  placeholder=""
                  :min="0"
                />
                <GroupInputNumber
                  v-model="vmodel.airporttransfer.nt"
                  :validation="v$.vmodel.airporttransfer.nt"
                  sectionClass="field mt-2"
                  id="nt"
                  label="Service charges of New Territories (From/To)"
                  labelHighLight="New Territories"
                  placeholder=""
                  :min="0"
                />
                <TextAreaRequire
                  v-model="vmodel.airporttransfer.remark"
                  :validation="v$.vmodel.airporttransfer.remark"
                  sectionClass="field mt-2"
                  class="w-full areaheight"
                  label="Remarks"
                  id="airportremark"
                  placeholder="Please enter..."
                  maxlength="50"
                />
              </div>
              <div class="flex flex-column col-4 width-column">
                <GroupInputNumber
                  v-model="vmodel.localtransfer.hk_hk"
                  :validation="v$.vmodel.localtransfer.hk_hk"
                  sectionClass="field"
                  id="hk_hk"
                  label="Hong Kong - Hong kong"
                  labelHighLight="Hong Kong - Hong kong"
                  placeholder=""
                  :min="0"
                />
                <GroupInputNumber
                  v-model="vmodel.localtransfer.hk_kl"
                  :validation="v$.vmodel.localtransfer.hk_kl"
                  sectionClass="field mt-2"
                  id="hk_kl"
                  label="Hong Kong - Kowloon"
                  labelHighLight="Hong Kong - Kowloon"
                  placeholder=""
                  :min="0"
                />
                <GroupInputNumber
                  v-model="vmodel.localtransfer.hk_nt"
                  :validation="v$.vmodel.localtransfer.hk_nt"
                  sectionClass="field mt-2"
                  id="hk_nt"
                  label="Hong Kong - New Territories"
                  labelHighLight="Hong Kong - New Territories"
                  placeholder=""
                  :min="0"
                />
                <GroupInputNumber
                  v-model="vmodel.localtransfer.kl_kl"
                  :validation="v$.vmodel.localtransfer.kl_kl"
                  sectionClass="field mt-2"
                  id="kl_kl"
                  label="Kowloon - Kowloon"
                  labelHighLight="Kowloon - Kowloon"
                  placeholder=""
                  :min="0"
                />
                <GroupInputNumber
                  v-model="vmodel.localtransfer.kl_nt"
                  :validation="v$.vmodel.localtransfer.kl_nt"
                  sectionClass="field mt-2"
                  id="kl_nt"
                  label="Kowloon - New Territories"
                  labelHighLight="Kowloon - New Territories"
                  placeholder=""
                  :min="0"
                />
                <GroupInputNumber
                  v-model="vmodel.localtransfer.nt_nt"
                  :validation="v$.vmodel.localtransfer.nt_nt"
                  sectionClass="field mt-2"
                  id="nt_nt"
                  label="New Territories - New Territories"
                  labelHighLight="New Territories - New Territories"
                  placeholder=""
                  :min="0"
                />
                <TextAreaRequire
                  v-model="vmodel.localtransfer.remark"
                  :validation="v$.vmodel.localtransfer.remark"
                  sectionClass="field mt-2"
                  class="w-full areaheight"
                  label="Remarks"
                  id="localremark"
                  placeholder="Please enter..."
                  maxlength="50"
                />
              </div>
              <div class="flex flex-column col-4 width-column">
                <GroupInputNumber
                  v-model="vmodel.limousineservice.servicecharge"
                  :validation="v$.vmodel.limousineservice.servicecharge"
                  sectionClass="field"
                  id="servicecharge"
                  label="Service charges per hour"
                  placeholder=""
                  :min="0"
                />
                <TextAreaRequire
                  v-model="vmodel.limousineservice.remark"
                  :validation="v$.vmodel.limousineservice.remark"
                  sectionClass="field mt-2"
                  class="w-full areaheight"
                  label="Remarks"
                  id="limousineservicecontent"
                  placeholder="Please enter..."
                  maxlength="50"
                />
              </div>
            </div>
          </div>

          <div class="mt-2">
            <PrimeButton
              label="Add"
              class="order-form-button"
              v-if="!isEditMode"
              @click="createCar"
            ></PrimeButton>
            <PrimeButton
              label="Save"
              class="order-edit-form-button"
              v-else
              @click="createCar"
            ></PrimeButton>
          </div>
        </div>
      </template>
    </PrimeCard>
  </div>
</template>
<script>
import APIService from "@/services/api-service.js";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  maxLength,
  numeric,
  minValue,
  maxValue,
} from "@vuelidate/validators";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      submitted: false,
      isEditMode: false,
      vmodel: {
        cartype: "",
        airporttransfer: {
          hk: 0,
          kl: 0,
          nt: 0,
          remark: "",
        },
        localtransfer: {
          hk_hk: 0,
          hk_kl: 0,
          hk_nt: 0,
          kl_kl: 0,
          kl_nt: 0,
          nt_nt: 0,
          remark: "",
        },
        limousineservice: {
          servicecharge: 0,
          remark: "",
        },
      },
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.isEditMode = true;
      this.getCarsById();
    } else {
      this.vmodel.newsid = Math.floor(
        10000000 + Math.random() * 90000000
      ).toString();
    }
  },
  validations() {
    return {
      vmodel: {
        cartype: { required, max: maxLength(50) },
        airporttransfer: {
          hk: {
            required,
            numeric,
            minValue: minValue(0),
            maxValue: maxValue(9999.99),
          },
          kl: {
            required,
            numeric,
            minValue: minValue(0),
            maxValue: maxValue(9999.99),
          },
          nt: {
            required,
            numeric,
            minValue: minValue(0),
            maxValue: maxValue(9999.99),
          },
          remark: { required, max: maxLength(100) },
        },
        localtransfer: {
          hk_hk: {
            required,
            numeric,
            minValue: minValue(0),
            maxValue: maxValue(9999.99),
          },
          hk_kl: {
            required,
            numeric,
            minValue: minValue(0),
            maxValue: maxValue(9999.99),
          },
          hk_nt: {
            required,
            numeric,
            minValue: minValue(0),
            maxValue: maxValue(9999.99),
          },
          kl_kl: {
            required,
            numeric,
            minValue: minValue(0),
            maxValue: maxValue(9999.99),
          },
          kl_nt: {
            required,
            numeric,
            minValue: minValue(0),
            maxValue: maxValue(9999.99),
          },
          nt_nt: {
            required,
            numeric,
            minValue: minValue(0),
            maxValue: maxValue(9999.99),
          },
          remark: { required, max: maxLength(100) },
        },
        limousineservice: {
          servicecharge: {
            required,
            numeric,
            minValue: minValue(0),
            maxValue: maxValue(9999.99),
          },
          remark: { required, max: maxLength(100) },
        },
      },
    };
  },
  methods: {
    async createCar() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        let payload = {
          cartype: this.vmodel.cartype,
          airporttransfer: this.vmodel.airporttransfer,
          localtransfer: this.vmodel.localtransfer,
          limousineservice: this.vmodel.limousineservice,
        };
        this.submitted = true;
        if (this.$route.params.id) {
          APIService.put("/car", payload, this.$route.params.id)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "CarManagement" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        } else {
          APIService.post("/car", payload)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "CarManagement" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        }
      }
    },
    async getCarsById() {
      APIService.getById("/car", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.cartype = response.data.data.cartype;
            this.vmodel.airporttransfer = response.data.data.airporttransfer;
            this.vmodel.localtransfer = response.data.data.localtransfer;
            this.vmodel.limousineservice = response.data.data.limousineservice;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "CarManagement" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/uploadimg.css";
.invalid {
  border-color: #dc3545 !important;
  border: 1px solid #dc3545 !important;
}
::v-deep(.areaheight) {
  .p-inputtext {
    width: inherit !important;
    min-height: 58px !important;
  }
}
::v-deep(.p-card .p-card-body) {
  padding: 40px 40px 40px 40px;
}
::v-deep(.user-card.p-card) {
  height: 100% !important;
  min-height: 86vh !important;
}

.set-width {
  width: calc(100% - 40px);
}

.width-column {
  flex: initial;
}
::v-deep(.field > label) {
  height: auto;
}
</style>
