import NewsManagement from "@/models/newsmanagement";

export default class NewsManagementFactory {
  static createFromJson(json) {
    return new NewsManagement(json);
  }

  static createFromJsonArray(jsonArray) {
    const jsonData = [];
    jsonArray.forEach((item) => {
      jsonData.push(NewsManagementFactory.createFromJson(item));
    });

    return jsonData;
  }
}
