<template>
  <div :class="sectionClass">
    <label :for="id" class="form-label" v-html="highlightLabel(label)"></label>
    <div
      class="p-inputgroup"
      :class="{
        'p-invalid': validation?.$error,
      }"
    >
      <span class="p-inputgroup-addon">
        <i class="doller-icon" style="color: #fff"></i>
      </span>
      <InputNumber
        :id="id"
        :useGrouping="false"
        :modelValue="modelValue"
        :disabled="disabled"
        :placeholder="placeholder"
        :minFractionDigits="2"
        :maxFractionDigits="2"
        :min="min"
        :max="max"
        :class="{
          'invalid-box': validation?.$error,
        }"
        :aria-describedby="`${id}-help`"
        @input="$emit('update:modelValue', $event.value)"
      />
    </div>
    <span
      v-if="
        validation.$error && validation.required && validation.required.$invalid
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.required.$message.replace("Value", this.label) }}
    </span>
    <span
      v-else-if="
        validation &&
        validation.$error &&
        validation.minValue.$invalid &&
        validation.minValue
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.minValue.$message }}
    </span>
    <span
      v-else-if="
        validation &&
        validation.$error &&
        validation.maxValue.$invalid &&
        validation.maxValue
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.maxValue.$message }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    labelClass: {
      type: String,
      default: "form-label",
    },
    sectionClass: {
      type: String,
      default: "field",
    },
    id: {
      type: String,
      required: true,
      default: "",
    },
    label: {
      type: String,
      required: true,
      default: "",
    },
    labelHighLight: {
      type: String,
      required: false,
    },
    validation: {
      type: Object,
      required: true,
    },
    minlength: {
      type: String,
      default: "",
    },
    maxlength: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Number,
      default: 0,
    },
    placeholder: {
      type: String,
      default: "",
    },
    validated: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    highlightLabel(label) {
      const regex = new RegExp(this.labelHighLight, "gi");
      return label.replace(regex, '<span class="highlighted-text">$&</span>');
    },
  },
};
</script>
