<template>
  <div>
    <Dialog
      v-model:visible="display"
      modal
      :style="{ width: '35vw' }"
      class="delete-popup"
    >
      <template #header
        ><i class="info-delete-icon"></i
        ><span class="dialog-title"> {{ header }}</span></template
      >
      <p v-html="message"></p>
      <template #footer>
        <PrimeButton
          label="Confirm"
          class="btn-delete"
          @click="confirmPopup"
        ></PrimeButton>
        <PrimeButton
          label="Cancel"
          class="btn-cancel"
          @click="closePopup"
        ></PrimeButton>
      </template>
    </Dialog>
  </div>
</template>
<script>
import Dialog from "primevue/dialog";

export default {
  components: {
    Dialog,
  },
  data() {
    return {
      display: false,
    };
  },
  props: {
    showPopup: {
      type: Boolean,
      default: false,
      required: true,
    },
    message: {
      type: String,
      default:
        "Are you sure you want to delete? <br/> Once you confirm this action, it will be deleted <br/> immediately. You can't undo this action.",
    },
    header: {
      type: String,
      default: "Delete",
    },
  },
  watch: {
    showPopup: {
      handler(newValue) {
        if (newValue === true) {
          this.openPopup();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    openPopup() {
      this.display = true;
    },
    confirmPopup() {
      this.$emit("confirmYes");
      this.display = false;
    },
    closePopup() {
      this.$emit("confirmNo");
      this.display = false;
    },
  },
};
</script>
<style lang="scss" scoped>
:global(.delete-popup.p-dialog) {
  padding: 24px 32px 32px 32px !important;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 464px !important;
  gap:24px;
}
:global(.delete-popup.p-dialog .p-dialog-header) {
  display: flex;
  justify-content: center !important;
  align-items: center;
  border-bottom: none;
  padding: 0rem;
}
:global(.delete-popup.p-dialog .p-dialog-header-icons) {
  display: none !important;
}
:global(.delete-popup.p-dialog .p-dialog-content) {
  display: flex;
  padding: 0rem;
  color: #1d2129;
  font-size: 16px;
  line-height: 24px;
  font-family: NunitoSans;
  font-weight: 400;
  font-style: normal;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
:global(.delete-popup.p-dialog .p-dialog-footer) {
  display: flex;
  border-top: none;
  margin: 1.25em auto 0;
  padding: 0;
}
</style>
