/* eslint-disable vue/multi-word-component-names */
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import PrimeVue from "primevue/config";
import store from "./store";

import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Button from "primevue/button";
import Card from "primevue/card";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import FileUpload from "primevue/fileupload";
import Rating from "primevue/rating";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import Dropdown from "primevue/dropdown";
import Textarea from "primevue/textarea";

import InputTextRequire from "@/components/form-control/input-text.vue";
import DropDownRequire from "@/components/form-control/drop-down.vue";
import TextAreaRequire from "@/components/form-control/text-area.vue";
import GroupInputNumber from "@/components/form-control/group-input-number.vue";

import ConfirmPopup from "@/components/modal-popup/confirm-popup.vue";
import BookingPopup from "@/components/modal-popup/booking-popup.vue";

import ConfirmationService from "primevue/confirmationservice";

import "primevue/resources/themes/bootstrap4-light-blue/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeflex/primeflex.min.css";
import "@/assets/css/global.css";

const app = createApp(App);
app.use(store);
app.use(PrimeVue);
app.use(ToastService);
app.use(ConfirmationService);

app.component("InputText", InputText);
app.component("InputNumber", InputNumber);
app.component("PrimeButton", Button);
app.component("PrimeCard", Card);
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("TabView", TabView);
app.component("TabPanel", TabPanel);
app.component("FileUpload", FileUpload);
app.component("Rating", Rating);
app.component("PrimeToast", Toast);
app.component("ToastService", ToastService);
app.component("Dropdown", Dropdown);
app.component("Textarea", Textarea);

app.component("InputTextRequire", InputTextRequire);
app.component("DropDownRequire", DropDownRequire);
app.component("TextAreaRequire", TextAreaRequire);
app.component("GroupInputNumber", GroupInputNumber);

app.component("ConfirmPopup", ConfirmPopup);
app.component("BookingPopup", BookingPopup);

app.use(router).mount("#app");
