import axios from "axios";
import store from "../store/index";
var baseURL = process.env.VUE_APP_API_URL;

export default {
  async get(url) {
    let AuthHeader = await this.authuser();
    var getURL = baseURL + url;
    store.state.showLoader = true;
    return await axios
      .get(getURL, AuthHeader)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error.response || error;
      })
      .finally(() => {
        store.state.showLoader = false;
      });
  },
  async getById(url, id) {
    const AuthHeader = await this.authuser();
    var getURL = baseURL + url;
    if (id != "" && id != null && id != undefined) {
      getURL = baseURL + url + "/" + id;
    }
    store.state.showLoader = true;
    return await axios
      .get(getURL, AuthHeader)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error.response || error;
      })
      .finally(() => {
        store.state.showLoader = false;
      });
  },
  async post(url, model, id, hasFormData = false) {
    const AuthHeader = await this.authuser(hasFormData);
    var apiurl = baseURL + url;
    if (id != null && id != undefined && id != "") {
      apiurl = baseURL + url + "/" + id;
    }
    store.state.showLoader = true;
    return await axios
      .post(apiurl, model, AuthHeader)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error.response || error;
      })
      .finally(() => {
        store.state.showLoader = false;
      });
  },
  async put(url, model, id) {
    const AuthHeader = await this.authuser();
    var apiurl = baseURL + url;
    if (id != null && id != undefined && id != "") {
      apiurl = baseURL + url + "/" + id;
    }
    store.state.showLoader = true;
    return await axios
      .put(apiurl, model, AuthHeader)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error.response || error;
      })
      .finally(() => {
        store.state.showLoader = false;
      });
  },
  async patch(url, model, id) {
    const AuthHeader = await this.authuser();
    var apiurl = baseURL + url;
    if (id != null && id != undefined && id != "") {
      apiurl = baseURL + url + "/" + id;
    }
    store.state.showLoader = true;
    return await axios
      .patch(apiurl, model, AuthHeader)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error.response || error;
      })
      .finally(() => {
        store.state.showLoader = false;
      });
  },
  async delete(url) {
    const AuthHeader = await this.authuser();
    var deleteURL = baseURL + url;
    store.state.showLoader = true;
    return await axios
      .delete(deleteURL, AuthHeader)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error.response || error;
      })
      .finally(() => {
        store.state.showLoader = false;
      });
  },
  async authuser(hasFormData = false) {
    if (store.state.hasLoggedIn && store.state.userToken) {
      return {
        headers: {
          Authorization: `Bearer ${store.state.userToken}`,
          "Content-Type": hasFormData
            ? "multipart/form-data"
            : "application/json",
          Accept: "application/json",
        },
      };
    }
    return null;
  },
};
