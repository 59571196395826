<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="dashboard-margin">
    <PrimeCard class="termsandcondition-card">
      <template #content>
        <span class="cms-title"> Content</span>
        <div class="col-12 p-0">
          <div class="grid m-0">
            <Textarea
              v-model="vmodel.content"
              sectionClass="field"
              class="textarea-style w-full"
              label="Product Description"
              id="description"
              autoResize
            />
          </div>
        </div>
        <div class="col-12">
          <div class="grid mt-2">
            <PrimeButton
              label="Save"
              class="order-edit-form-button"
              @click="AboutUsEdit"
            ></PrimeButton>
          </div>
        </div>
      </template>
    </PrimeCard>
  </div>
</template>
<script>
import APIService from "@/services/api-service.js";

export default {
  data() {
    return {
      submitted: false,
      isEditMode: false,
      vmodel: {
        content: "",
      },
    };
  },
  mounted() {
    if (this.$route.name) {
      this.getAboutUsId();
    } else {
      this.dataLoaded = true;
    }
  },

  methods: {
    async getAboutUsId() {
      APIService.getById("/cmspages", this.$route.name)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.content = response.data.data.content;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
          this.dataLoaded = true;
        });
    },
    async AboutUsEdit() {
      let payload = {
        content: this.vmodel.content,
      };
      if (this.$route.name) {
        APIService.patch("/cmspages", payload, this.$route.name)
          .then((response) => {
            if (response && response.data && response.data.success) {
              this.$toast.add({
                severity: "success",
                summary: "Success",
                detail: response.data.message,
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.data.message,
                life: 3000,
              });
            }
          })
          .finally(() => {
            this.submitted = false;
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.textarea-style {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px 12px;
  min-height: 100px;
  background: #f2f3f5;
  border-radius: 2px;
  font-family: NunitoSans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #1d2129;
  box-shadow: none !important;
  border: none;
  resize: auto;
}
::v-deep(.p-card .p-card-content) {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
::v-deep(.termsandcondition-card.p-card) {
  height: 100% !important;
  min-height: 86vh !important;
}
</style>
