<template>
  <div :class="sectionClass">
    <label :for="id" class="form-label">{{ label }}</label>
    <Dropdown
      :id="id"
      :modelValue="modelValue"
      :options="options"
      :optionLabel="optionLabel"
      :optionValue="optionValue"
      :disabled="disabled"
      :placeholder="placeholder"
      dropdownIcon="dropdown-icon"
      :class="{
        'p-invalid': validation?.$error,
      }"
      :aria-describedby="`${id}-help`"
      @change="$emit('update:modelValue', $event.value)"
    />
    <span
      v-if="
        validation.$error && validation.required && validation.required.$invalid
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.required.$message.replace("Value", this.label) }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    sectionClass: {
      type: String,
      default: "field",
    },
    id: {
      type: String,
      required: true,
      default: "",
    },
    label: {
      type: String,
      required: true,
      default: "",
    },
    validation: {
      type: Object,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: String,
      default: "",
    },
    optionLabel: {
      type: String,
      default: "",
    },
    optionValue: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
