<template>
  <div class="dashboard-margin">
    <PrimeCard class="user-card">
      <template #content>
        <div>
          <div class="col-12">
            <!-- <div class="grid">
              <div class="col-4">
                <InputTextRequire
                  v-model="vmodel.userid"
                  sectionClass="field"
                  id="userid"
                  label="User ID"
                  class="disable-text-field"
                  placeholder="User Id"
                  disabled
                  v-if="isEditMode"
                />
              </div>
            </div> -->
            <div class="grid">
              <div class="col-4">
                <InputTextRequire
                  v-model="vmodel.username"
                  :validation="v$.vmodel.username"
                  sectionClass="field"
                  id="username"
                  label="User Name"
                  placeholder="User Name"
                  maxlength="50"
                />
              </div>
            </div>
            <div class="grid">
              <div class="col-4">
                <InputTextRequire
                  v-model="vmodel.email"
                  :validation="v$.vmodel.email"
                  sectionClass="field"
                  id="email"
                  label="Email"
                  placeholder="Email"
                  maxlength="50"
                  @keypress="notAllowSpace"
                />
              </div>
            </div>
            <div class="grid">
              <div class="col-4">
                <InputTextRequire
                  v-model="vmodel.phone"
                  :validation="v$.vmodel.phone"
                  sectionClass="field"
                  id="phone"
                  label="Phone"
                  placeholder="Phone"
                  maxlength="8"
                  minlength="8"
                  @keypress="onlyDigit"
                />
              </div>
            </div>

            <div v-if="!isEditMode" class="grid">
              <div class="col-4 field">
                <label for="password" class="form-label float-left">
                  Password</label
                >
                <InputText
                  v-model="vmodel.password"
                  sectionClass="field"
                  class="pass"
                  type="password"
                  id="password"
                  placeholder="Password"
                  maxlength="12"
                  minlength="8"
                  :class="{
                    'p-invalid': v$.vmodel.password.$error,
                  }"
                  aria-describedby="password-help"
                  @keypress="notAllowSpace"
                />
                <span
                  v-if="
                    v$.vmodel.password.$error &&
                    v$.vmodel.password.required.$invalid
                  "
                  id="password-help"
                  class="p-error text-left mt-2"
                >
                  {{
                    v$.vmodel.password.required.$message.replace(
                      "Value",
                      "Password"
                    )
                  }}
                </span>
                <span
                  v-else-if="
                    v$.vmodel.password.$error && v$.vmodel.password.min.$invalid
                  "
                  id="password-help"
                  class="p-error mt-2"
                >
                  {{ v$.vmodel.password.min.$message }}
                </span>
                <span
                  v-else-if="
                    v$.vmodel.password.$error && v$.vmodel.password.max.$invalid
                  "
                  id="password-help"
                  class="p-error mt-2"
                >
                  {{ v$.vmodel.password.max.$message }}
                </span>
              </div>
            </div>
            <div v-if="!isEditMode" class="grid">
              <div class="col-4 field">
                <label for="confirmpassword" class="form-label float-left">
                  Confirm Password</label
                >
                <InputText
                  v-model="vmodel.confirmpassword"
                  type="password"
                  sectionClass="field"
                  class="pass"
                  id="confirmpassword"
                  placeholder="Confirm Password"
                  maxlength="12"
                  minlength="8"
                  :class="{
                    'p-invalid': v$.vmodel.confirmpassword.$error,
                  }"
                  aria-describedby="confirmpassword-help"
                  @keypress="notAllowSpace"
                />
                <span
                  v-if="
                    v$.vmodel.confirmpassword.$error &&
                    v$.vmodel.confirmpassword.required.$invalid
                  "
                  id="confirmpassword-help"
                  class="p-error text-left mt-2"
                >
                  {{
                    v$.vmodel.confirmpassword.required.$message.replace(
                      "Value",
                      "Confirm Password"
                    )
                  }}
                </span>
                <span
                  v-else-if="
                    v$.vmodel.confirmpassword.$error &&
                    v$.vmodel.confirmpassword.min.$invalid
                  "
                  id="confirmpassword-help"
                  class="p-error mt-2"
                >
                  {{ v$.vmodel.confirmpassword.min.$message }}
                </span>
                <span
                  v-else-if="
                    v$.vmodel.confirmpassword.$error &&
                    v$.vmodel.confirmpassword.max.$invalid
                  "
                  id="confirmpassword-help"
                  class="p-error mt-2"
                >
                  {{ v$.vmodel.confirmpassword.max.$message }}
                </span>
                <span
                  v-else-if="
                    v$.vmodel.confirmpassword.$error &&
                    v$.vmodel.confirmpassword.sameAs.$invalid
                  "
                  id="confirmpassword-help"
                  class="p-error text-left mt-2"
                >
                  Password and Confirm Password must be match
                </span>
              </div>
            </div>
          </div>
          <div class="mt-4 ml-2">
            <PrimeButton
              label="Add"
              class="order-form-button"
              v-if="!isEditMode"
              @click="createMember"
            ></PrimeButton>
            <PrimeButton
              label="Save"
              class="order-edit-form-button"
              v-else
              @click="createMember"
            ></PrimeButton>
          </div>
        </div>
      </template>
    </PrimeCard>
  </div>
</template>
<script>
import APIService from "@/services/api-service.js";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  email,
  maxLength,
  minLength,
  sameAs,
  numeric,
} from "@vuelidate/validators";
import getPassword from "@/libs/password";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      submitted: false,
      isEditMode: false,
      vmodel: {
        userid: "",
        username: "",
        email: "",
        phone: "",
        password: "",
        confirmpassword: "",
      },
    };
  },

  mounted() {
    if (this.$route.params.id) {
      this.isEditMode = true;
      this.getUserById();
    }
  },
  validations() {
    if (this.isEditMode) {
      return {
        vmodel: {
          username: { required, max: maxLength(50) },
          phone: { min: minLength(8), max: maxLength(8), numeric },
          email: { required, email, max: maxLength(50) },
        },
      };
    } else {
      return {
        vmodel: {
          username: { required, max: maxLength(50) },
          phone: { min: minLength(8), max: maxLength(8), numeric },
          email: { required, email, max: maxLength(50) },
          password: { required, min: minLength(8), max: maxLength(12) },
          confirmpassword: {
            required,
            min: minLength(8),
            max: maxLength(12),
            sameAs: sameAs(this.vmodel.password),
          },
        },
      };
    }
  },
  methods: {
    onlyDigit($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
    },
    notAllowSpace($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode == 32) {
        $event.preventDefault();
      }
    },
    async createMember() {
      this.vmodel.username = this.vmodel.username.trim();
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        let payload = {
          username: this.vmodel.username,
          email: this.vmodel.email,
          phone: this.vmodel.phone,
        };
        this.submitted = true;
        if (this.$route.params.id) {
          APIService.patch(
            "/membermanagement/member",
            payload,
            this.$route.params.id
          )
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "MemberManagement" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        } else {
          let password = getPassword.generatePassword(this.vmodel.password);
          let encodedPassword = encodeURIComponent(password);
          let payload = {
            username: this.vmodel.username,
            email: this.vmodel.email,
            phone: this.vmodel.phone,
            password: encodedPassword,
          };
          APIService.post("/membermanagement/addaccount", payload)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "MemberManagement" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        }
      }
    },
    async getUserById() {
      APIService.getById("/membermanagement/member", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            // this.vmodel.userid = response.data.data.userid;
            this.vmodel.username = response.data.data.username;
            this.vmodel.email = response.data.data.email;
            this.vmodel.phone = response.data.data.phone;
            // let password = decodeURIComponent(response.data.data.password);
            // let decryptedPassword = getPassword.getPassword(password);
            // this.vmodel.password = decryptedPassword;
            // this.vmodel.confirmpassword = decryptedPassword;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "MemberManagement" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.invalid {
  border-color: #dc3545 !important;
  border: 1px solid #dc3545 !important;
}
::v-deep(.p-card .p-card-body) {
  padding: 40px 40px 40px 40px;
}
::v-deep(.user-card.p-card) {
  height: 100% !important;
  min-height: 86vh !important;
}
</style>
