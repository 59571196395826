<template>
  <div class="dashboard-margin">
    <PrimeCard class="user-card">
      <template #content>
        <div class="col-12">
          <div class="grid">
            <div class="col-4">
              <label for="photo" class="form-label">Upload Image</label>
              <div
                class="photo-section cursor-pointer mt-2 mb-3"
                @click="openFileUpload"
                v-if="image == ''"
                :class="{
                  invalid: v$.vmodel.image.$error,
                }"
              >
                <span class="upload-icon"></span>
                <div class="upload-text">
                  Drag and Drop a files here or click
                </div>
              </div>
              <div class="mt-2 mb-3 upload-img-box" v-if="image !== ''">
                <img
                  :src="vmodel.image"
                  alt=""
                  class="upload-img"
                  height="80"
                  width="80"
                />
              </div>
              <FileUpload
                ref="photo"
                name="photo"
                @select="handleFileUpload($event)"
                accept=".png, .jpg, .jpeg"
                v-show="false"
                :aria-describedby="`photo-help`"
              />
              <div
                v-if="
                  v$.vmodel.image.$error &&
                  v$.vmodel.image.required &&
                  v$.vmodel.image.required.$invalid
                "
                class="p-error mt-2"
              >
                {{
                  v$.vmodel.image.required.$message.replace("Value", "Image")
                }}
              </div>
              <div class="grid" v-if="vmodel.image !== ''">
                <div
                  class="col-12 flex align-items-center relative"
                  style="margin-top: 24px"
                >
                  <div class="catalogue-label">
                    <img :src="vmodel.image" alt="" class="img-style" />
                    <span class="set-margin">{{ vmodel.imagename }}</span>
                    <i
                      class="download-icon cursor-pointer"
                      @click="downloadfile"
                    ></i>
                  </div>
                  <i class="delete-icon cursor-pointer" @click="deletefile"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="grid">
            <div class="col-4 flex flex-column">
              <InputTextRequire
                v-model="vmodel.title"
                :validation="v$.vmodel.title"
                sectionClass="field"
                id="title"
                label="News Title"
                placeholder="Title"
                maxlength="50"
              />
              <!-- <label for="photo" class="form-label">News Content</label> -->
              <TextAreaRequire
                v-model="vmodel.content"
                :validation="v$.vmodel.content"
                sectionClass="field"
                class="w-full areaheight"
                label="News Content"
                id="content"
                placeholder="Please enter..."
                maxlength="50"
              />
            </div>
          </div>

          <div class="mt-2">
            <PrimeButton
              label="Add"
              class="order-form-button"
              v-if="!isEditMode"
              @click="createNews"
            ></PrimeButton>
            <PrimeButton
              label="Save"
              class="order-edit-form-button"
              v-else
              @click="createNews"
            ></PrimeButton>
          </div>
        </div>
      </template>
    </PrimeCard>
  </div>
</template>
<script>
import APIService from "@/services/api-service.js";
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength } from "@vuelidate/validators";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      submitted: false,
      isEditMode: false,
      image: "",
      imagename: "",
      vmodel: {
        newsid: "",
        image: "",
        title: "",
        content: "",
        imagename: "",
      },
    };
  },

  mounted() {
    if (this.$route.params.id) {
      this.isEditMode = true;
      this.getNewsById();
    } else {
      this.vmodel.newsid = Math.floor(
        10000000 + Math.random() * 90000000
      ).toString();
    }
  },
  validations() {
    return {
      vmodel: {
        image: { required },
        title: { required, max: maxLength(50) },
        content: { required, max: maxLength(100) },
      },
    };
  },
  methods: {
    async deletefile() {
      this.vmodel.image = "";
      this.image = "";
    },
    async downloadfile() {
      let image = new Image();
      image.src = this.vmodel.image;
      var newTab = window.open();
      newTab.document.body.innerHTML = image.outerHTML;
    },
    openFileUpload() {
      this.$refs.photo.choose();
    },
    async createNews() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        let payload = {
          newsid: this.vmodel.newsid,
          image: this.image,
          title: this.vmodel.title,
          content: this.vmodel.content,
          imagename: this.vmodel.imagename,
        };
        this.submitted = true;
        if (this.$route.params.id) {
          APIService.put("/news", payload, this.$route.params.id)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "NewsManagement" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        } else {
          APIService.post("/news", payload)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "NewsManagement" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        }
      }
    },
    async getNewsById() {
      this.image = "";
      APIService.getById("/news", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.newsid = response.data.data.newsid;
            this.image = response.data.data.image;
            this.vmodel.image = response.data.data.path;
            this.vmodel.title = response.data.data.title;
            this.vmodel.content = response.data.data.content;
            this.vmodel.imagename = response.data.data.imagename;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "NewsManagement" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    handleFileUpload(fileObj) {
      var file = this.$refs.photo.files[0];
      if (
        !fileObj ||
        (fileObj && !fileObj.files) ||
        (fileObj && fileObj.files && fileObj.files.length === 0)
      ) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please select valid Image",
          life: 3000,
        });
        this.$refs.photo.clear();
      } else if (fileObj && fileObj.files && fileObj.files[0].size > 1000000) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Image size should be less then 1 MB",
          life: 3000,
        });
        this.$refs.photo.clear();
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.image = reader.result;
          this.vmodel.image = reader.result;
          this.vmodel.imagename = file.name;
        };
      }
      this.$refs.photo.clear();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/uploadimg.css";
.invalid {
  border-color: #dc3545 !important;
  border: 1px solid #dc3545 !important;
}
::v-deep(.areaheight) {
  .p-inputtext {
    width: inherit !important;
    min-height: 58px !important;
  }
}
.img-style {
  height: 22.5px;
  width: 40px;
}
.photo-section {
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 16px;
  gap: 24px;

  // width: 415px;
  width: 100% !important;
  height: 160px;

  background: #f2f3f5;

  border: 1px dashed #e5e6eb;
  border-radius: 2px;
}
.catalogue-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 12px;
  isolation: isolate;

  width: 100%;
  height: 38px;
  background: #f7f8fa;
  border-radius: 2px;
}
.download-icon {
  position: absolute;
  right: 12px;
}
.delete-icon {
  position: absolute;
  right: -12px;
}
.set-margin {
  margin-left: 13px;
  width: 315px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}
.remove-icon {
  margin-right: -20px;
}
.upload-form-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 16px;
  gap: 8px;

  width: 153px;
  height: 32px;

  /* Primary/1 */

  background: #8c181b;
  border-radius: 2px;

  font-family: PingFang SC;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  display: flex;
  align-items: center;

  /* 特殊/White */

  color: #ffffff;
}

.upload-img {
  border-radius: 1px;
  align-self: center;
  align-content: end;
  text-align: center;
  margin: inherit;
  margin-left: 134px;
}
.upload-text {
  font-family: NunitoSans;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  height: 22px;
  color: #1d2129;
}
::v-deep(.p-card .p-card-body) {
  padding: 40px 40px 40px 40px;
}
::v-deep(.user-card.p-card) {
  height: 100% !important;
  min-height: 86vh !important;
}
</style>
