<template>
  <div class="dashboard-margin">
    <PrimeCard class="newslist-card">
      <template #content>
        <DataTable
          :value="news"
          :paginator="true"
          :lazy="true"
          class="p-datatable-lg p-datatable-customers"
          :rows="itemsPerPage"
          :totalRecords="totalRecords"
          dataKey="id"
          :rowHover="true"
          :resizableColumns="true"
          @page="onPage($event)"
          @sort="onSort($event)"
          paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} results"
        >
          <template #header>
            <PrimeButton
              label="ADD"
              class="add-button"
              icon="plus-icon"
              @click="gotoCreateNews"
            ></PrimeButton>
          </template>
          <template #loading>Loading records, please wait...</template>
          <template #empty>No records found.</template>
          <Column field="newsid" header="#" sortable></Column>
          <Column field="title" header="News Title" sortable>
            <template #body="row">
              <div class="flex">
                <span class="margin-title">{{ row.data.title }}</span>
              </div>
            </template>
          </Column>
          <Column field="content" header="News Content" sortable>
            <template #body="row">
              <div class="flex">
                <span class="margin-content">{{ row.data.content }}</span>
              </div>
            </template>
          </Column>
          <Column field="action" header="Action">
            <template #body="data">
              <div class="action-cloumn">
                
                <PrimeButton
                  label="Edit"
                  class="edit-button"
                  @click="gotoNewsEdit(data.data.id)"
                />
                <PrimeButton
                  label="Delete"
                  class="delete-button"
                  @click="showDeletePopup(data.data.id)"
                />
              </div>
            </template>
          </Column>
        </DataTable>
        <div>
          <ConfirmPopup
            :show-popup="showPopup"
            :message="message"
            :header="header"
            @confirmYes="deleteNews"
            @confirmNo="showPopup = false"
          ></ConfirmPopup>
        </div>
      </template>
    </PrimeCard>
  </div>
</template>
<script>
import APIService from "@/services/api-service.js";
import NewsManagementFactory from "@/factories/newsmanagement";
export default {
  data() {
    return {
      currentPage: 0,
      itemsPerPage: 10,
      totalRecords: 0,
      sortColumn: "id",
      sortDir: "asc",
      news: [],
      message: "",
      header: "",
      newsIdForDelete: "",
      showPopup: false,
    };
  },
  mounted() {
    this.getNewsList();
  },
  methods: {
    gotoCreateNews() {
      this.$router.push({ name: "NewsManagementCreate" });
    },
    gotoNewsEdit(newsId) {
      this.$router.push({
        name: "NewsManagementEdit",
        params: { id: newsId },
      });
    },
    showDeletePopup(newsId) {
      this.newsIdForDelete = newsId;
      this.message =
        "Are you sure you want to delete the news? <br/> Once you confirm this action, the account will be <br/> deleted immediately. You can't undo this action.";
      this.header = "Delete News";
      this.showPopup = true;
    },
    getNewsList() {
      this.news = [];
      APIService.get(
        `/news/${this.currentPage}/${this.itemsPerPage}/${this.sortColumn}/${this.sortDir}`
      ).then((response) => {
        if (response && response.data) {
          this.totalRecords = response.data.totalrecord;
          this.news = NewsManagementFactory.createFromJsonArray(
            response.data.data
          );
        }
      });
    },
    deleteNews() {
      APIService.delete(`/news/delete/${this.newsIdForDelete}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.getNewsList();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.showPopup = false;
        });
    },
    onPage(pageData) {
      this.currentPage = pageData.first;
      this.getNewsList();
    },
    onSort(sortData) {
      let sortField = sortData.sortField;
      if (sortData.sortField === "createddate") {
        sortField = "createddate";
      }
      this.sortColumn = sortField;
      this.sortDir = sortData.sortOrder === 1 ? "asc" : "dsc";
      this.currentPage = 0;

      this.getNewsList();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/datatable.scss";
::v-deep(.p-card .p-card-body) {
  padding: 40px 20px auto 20px;
}
.margin-title {
  max-width: 357px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.margin-content {
  max-width: 545px;
  overflow: hidden;
  text-overflow: ellipsis;
}
::v-deep(.newslist-card.p-card) {
  height: 100% !important;
  min-height: 86vh !important;
}
</style>
