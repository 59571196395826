import { createWebHistory, createRouter } from "vue-router";
import Store from "@/store/index";
import MainLayout from "@/views/main-layout.vue";
import Login from "@/views/login.vue";
import MemberManagement from "@/views/member-management/member-management-list.vue";
import MemberManagementCreate from "@/views/member-management/member-management-form.vue";
import NewsManagement from "@/views/news-management/news-management-list.vue";
import NewsManagementCreate from "@/views/news-management/news-management-form.vue";
import CarManagement from "@/views/car-management/car-management-list.vue";
import CarManagementCreate from "@/views/car-management/car-management-form.vue";
import ReservationRecordList from "@/views/reservation-record/reservation-record-list.vue";
import PaymentMethod from "@/views/payment-method/payment-method-list.vue";
import PaymentMethodCreate from "@/views/payment-method/payment-method-form.vue";
import AboutUs from "@/views/CMSPages/about-us.vue";
import ContactUs from "@/views/CMSPages/contact-us.vue";
import PrivacyPolicy from "@/views/CMSPages/privacy-policy.vue";
import TermsandConditions from "@/views/CMSPages/terms-and-conditions.vue";
import ProfileSetting from "@/views/profile-setting/profile-setting.vue";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "Login",
    },
  },
  {
    component: MainLayout,
    children: [
      {
        path: "/member-management",
        name: "MemberManagement",
        component: MemberManagement,
        meta: {
          requiresAuth: true,
          module: "MemberManagement",
          title: "Member Management",
        },
      },
      {
        path: "/member-management/create",
        name: "MemberManagementCreate",
        component: MemberManagementCreate,
        meta: {
          requiresAuth: true,
          module: "MemberManagement",
          title: "Member Management / Add",
        },
      },
      {
        path: "/member-management/edit/:id",
        name: "MemberManagementEdit",
        component: MemberManagementCreate,
        meta: {
          requiresAuth: true,
          module: "MemberManagement",
          title: "Member Management / Edit",
        },
      },
      {
        path: "/news-management",
        name: "NewsManagement",
        component: NewsManagement,
        meta: {
          requiresAuth: true,
          module: "NewsManagement",
          title: "News Management",
        },
      },
      {
        path: "/news-management/create",
        name: "NewsManagementCreate",
        component: NewsManagementCreate,
        meta: {
          requiresAuth: true,
          module: "NewsManagement",
          title: "News Management / Add",
        },
      },
      {
        path: "/news-management/edit/:id",
        name: "NewsManagementEdit",
        component: NewsManagementCreate,
        meta: {
          requiresAuth: true,
          module: "NewsManagement",
          title: "News Management / Edit",
        },
      },
      {
        path: "/news-management",
        name: "NewsManagement",
        component: NewsManagement,
        meta: {
          requiresAuth: true,
          module: "NewsManagement",
          title: "News Management",
        },
      },
      {
        path: "/news-management/create",
        name: "NewsManagementCreate",
        component: NewsManagementCreate,
        meta: {
          requiresAuth: true,
          module: "NewsManagement",
          title: "News Management / Add",
        },
      },
      {
        path: "/news-management/edit/:id",
        name: "NewsManagementEdit",
        component: NewsManagementCreate,
        meta: {
          requiresAuth: true,
          module: "NewsManagement",
          title: "News Management / Edit",
        },
      },
      {
        path: "/car-management",
        name: "CarManagement",
        component: CarManagement,
        meta: {
          requiresAuth: true,
          module: "CarManagement",
          title: "Car Management",
        },
      },
      {
        path: "/car-management/create",
        name: "CarManagementCreate",
        component: CarManagementCreate,
        meta: {
          requiresAuth: true,
          module: "CarManagement",
          title: "Car Management / Add",
        },
      },
      {
        path: "/car-management/edit/:id",
        name: "CarManagementEdit",
        component: CarManagementCreate,
        meta: {
          requiresAuth: true,
          module: "CarManagement",
          title: "Car Management / Edit",
        },
      },
      {
        path: "/reservation-record",
        name: "ReservationRecord",
        component: ReservationRecordList,
        meta: {
          requiresAuth: true,
          module: "ReservationRecord",
          title: "Reservation Record",
        },
      },
      {
        path: "/payment-method",
        name: "PaymentMethod",
        component: PaymentMethod,
        meta: {
          requiresAuth: true,
          module: "PaymentMethod",
          title: "Payment Method",
        },
      },
      {
        path: "/payment-method/create",
        name: "PaymentMethodCreate",
        component: PaymentMethodCreate,
        meta: {
          requiresAuth: true,
          module: "PaymentMethod",
          title: "Payment Method / Add",
        },
      },
      {
        path: "/payment-method/edit/:id",
        name: "PaymentMethodEdit",
        component: PaymentMethodCreate,
        meta: {
          requiresAuth: true,
          module: "PaymentMethod",
          title: "Payment Method / Edit",
        },
      },
      {
        path: "/about-us",
        name: "AboutUs",
        component: AboutUs,
        meta: {
          requiresAuth: true,
          module: "AboutUs",
          title: "About Us",
        },
      },
      {
        path: "/contact-us",
        name: "ContactUs",
        component: ContactUs,
        meta: {
          requiresAuth: true,
          module: "ContactUs",
          title: "Contact Us",
        },
      },
      {
        path: "/privacy-policy",
        name: "PrivacyPolicy",
        component: PrivacyPolicy,
        meta: {
          requiresAuth: true,
          module: "PrivacyPolicy",
          title: "Privacy Policy",
        },
      },
      {
        path: "/terms-conditions",
        name: "TermsAndConditions",
        component: TermsandConditions,
        meta: {
          requiresAuth: true,
          module: "TermsAndConditions",
          title: "Terms and conditions",
        },
      },
      {
        path: "/profile-setting",
        name: "ProfileSetting",
        component: ProfileSetting,
        meta: {
          requiresAuth: true,
          module: "ProfileSetting",
          title: "Profile Setting",
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
// router.beforeEach((to) => {
//   Store.commit("setActiveRoute", to.meta.module);
// });
router.beforeEach((to, from, next) => {
  const title = "Top Apex";
  if (
    Store.state.timeout == null ||
    (Store.state.timeout != null && Store.state.timeout > new Date())
  ) {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (Store.state.hasLoggedIn) {
        Store.commit("setUserTimeout");
        Store.commit("setActiveRoute", to.meta.module);
        window.document.title =
          to.meta && to.meta.title ? title + " - " + to.meta.title : title;
        next();
        return;
      }
      Store.dispatch("logout");
      next("/login");
      return;
    } else if (to.name === undefined) {
      if (Store.state.hasLoggedIn) {
        Store.commit("setActiveRoute", to.meta.module);
        window.document.title =
          to.meta && to.meta.title ? title + " - " + to.meta.title : title;
        next();
        return;
      }
      Store.dispatch("logout");
      next("/login");
      return;
    } else {
      Store.commit("setUserTimeout");
      if (to.name == "Login" && Store.state.hasLoggedIn) {
        Store.commit("setActiveRoute", "Dashboard");
        next("/dashboard");
        return;
      } else {
        window.document.title =
          to.meta && to.meta.title ? title + " - " + to.meta.title : title;
        next();
        return;
      }
    }
  } else {
    Store.dispatch("logout");
    next("/login");
    return;
  }
});
export default router;
