import ReservationRecord from "@/models/reservationrecord";

export default class ReservationRecordFactory {
  static createFromJson(json) {
    return new ReservationRecord(json);
  }

  static createFromJsonArray(jsonArray) {
    const jsonData = [];
    jsonArray.forEach((item) => {
      jsonData.push(ReservationRecordFactory.createFromJson(item));
    });

    return jsonData;
  }
}
