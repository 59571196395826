<template>
  <SidebarMenu />
  <div style="margin-left: 300px">
    <router-view></router-view>
  </div>
  <PrimeToast />
</template>

<script>
import SidebarMenu from "@/components/layout/sidebar-menu.vue";

export default {
  components: {
    SidebarMenu,
  },
};
</script>
