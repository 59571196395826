<template>
  <Dialog v-model:visible="display" modal header="Booking" @hide="closePopup">
    <div>
      <table class="table-data">
        <tr>
          <th><span class="table-header">Reservation No.</span></th>
          <td>
            <span class="table-data-value">{{
              reservationRecord.reservationid
            }}</span>
          </td>
        </tr>
        <tr>
          <th><span class="table-header">User ID</span></th>
          <td>
            <span class="table-data-value">{{ reservationRecord.userid }}</span>
          </td>
        </tr>
        <tr>
          <th><span class="table-header">User name</span></th>
          <td>
            <span class="table-data-value">{{
              reservationRecord.username
            }}</span>
          </td>
        </tr>
        <tr>
          <th><span class="table-header">Phone</span></th>
          <td>
            <span class="table-data-value">{{ reservationRecord.phone }}</span>
          </td>
        </tr>
        <tr>
          <th><span class="table-header">Email</span></th>
          <td>
            <span class="table-data-value">{{ reservationRecord.email }}</span>
          </td>
        </tr>
        <tr>
          <th><span class="table-header">Contact Name</span></th>
          <td>
            <span class="table-data-value">{{
              reservationRecord.contactname
            }}</span>
          </td>
        </tr>
        <tr>
          <th><span class="table-header">Contact Phone</span></th>
          <td>
            <span class="table-data-value">{{
              reservationRecord.contactphone
            }}</span>
          </td>
        </tr>
        <tr>
          <th>
            <span class="table-header">Reservation Service</span>
          </th>
          <td>
            <span class="table-data-value">{{
              reservationRecord.service
            }}</span>
          </td>
        </tr>
        <tr>
          <th>
            <span class="table-header">Car type & model</span>
          </th>
          <td>
            <span class="table-data-value">{{
              reservationRecord.cartype
            }}</span>
          </td>
        </tr>
        <tr>
          <th><span class="table-header">Pick-up area</span></th>
          <td>
            <span class="table-data-value">{{
              reservationRecord.pickuparea
            }}</span>
          </td>
        </tr>
        <tr>
          <th><span class="table-header">Drop-off area</span></th>
          <td>
            <span class="table-data-value">{{
              reservationRecord.dropoffarea
            }}</span>
          </td>
        </tr>
        <tr>
          <th><span class="table-header">Rental duration</span></th>
          <td>
            <span class="table-data-value">{{
              reservationRecord.duration
            }}</span>
          </td>
        </tr>
        <tr>
          <th><span class="table-header">Reservation date</span></th>
          <td>
            <span class="table-data-value">{{
              reservationRecord.reservationdate
            }}</span>
          </td>
        </tr>
        <tr>
          <th><span class="table-header">Reservation time</span></th>
          <td>
            <span class="table-data-value">{{
              reservationRecord.reservationtime
            }}</span>
          </td>
        </tr>
        <tr>
          <th><span class="table-header">Payment method</span></th>
          <td>
            <span class="table-data-value">{{
              reservationRecord.paymentmethod
            }}</span>
          </td>
        </tr>
        <tr>
          <th><span class="table-header">Service charges</span></th>
          <td>
            <span class="table-data-value">${{
              reservationRecord.servicecharge
            }}</span>
          </td>
        </tr>
        <tr>
          <th><span class="table-header">Received Date</span></th>
          <td>
            <span class="table-data-value">{{
              reservationRecord.receivedate
            }}</span>
          </td>
        </tr>
        <tr>
          <th><span class="table-header">Status</span></th>
          <td>
            <span class="table-data-value">
              <Dropdown
                v-model="reservationRecord.status"
                :options="status"
                optionLabel="label"
                optionValue="value"
                placeholder="Select a Status"
              />
            </span>
          </td>
        </tr>
      </table>
      <div class="mt-3">
        <PrimeButton
          label="Update Status"
          class="changestatus-button"
          @click="EditStatus()"
        ></PrimeButton>
      </div>
    </div>
  </Dialog>
</template>
<script>
import Dialog from "primevue/dialog";
import APIService from "@/services/api-service.js";
import DateFormat from "@/libs/DateFormat";

export default {
  components: {
    Dialog,
  },
  data() {
    return {
      status: [],
      display: false,
      reservationRecord: {
        reservationid: "",
        userid: "",
        username: "",
        phone: "",
        email: "",
        contactname: "",
        contactphone: "",
        service: "",
        cartype: "",
        pickuparea: "",
        dropoffarea: "",
        duration: "",
        reservationdate: "",
        reservationtime: "",
        paymentmethod: "",
        servicecharge: "",
        receivedate: "",
        status: "inprogress",
      },
    };
  },
  props: {
    showPopup: {
      type: Boolean,
      default: false,
      required: true,
    },
    recordId: {
      type: String,
      required: true,
    },
  },
  watch: {
    showPopup: {
      handler(newValue) {
        if (newValue === true) {
          this.openPopup();
        }
      },
      deep: true,
      immediate: true,
    },
    recordId: {
      handler(newValue) {
        if (newValue !== "") {
          this.reservationRecordView();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async disableDropdownOptions() {
      const statusSequence = this.status.filter(
        (x) =>
          x.value.toLowerCase() === this.reservationRecord.status.toLowerCase()
      );
      if (statusSequence && statusSequence.length > 0) {
        const data = this.status.filter(
          (x) => x.sequence >= statusSequence[0].sequence
        );
        this.status = data;
      }
    },
    reservationRecordView() {
      APIService.get(`/reservation/details/${this.recordId}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.reservationRecord.reservationid =
              response.data.data.reservationno;
            this.reservationRecord.userid = response.data.data.userID;
            this.reservationRecord.username = response.data.data.username;
            this.reservationRecord.phone = response.data.data.Phone;
            this.reservationRecord.email = response.data.data.email;
            this.reservationRecord.contactname = response.data.data.contactname;
            this.reservationRecord.contactphone = response.data.data.contactno;
            this.reservationRecord.service = response.data.data.reservationtype;
            this.reservationRecord.cartype = response.data.data.carmodeltype;
            if (response.data.data.pickuparea) {
              this.reservationRecord.pickuparea = response.data.data.pickuparea;
            } else {
              this.reservationRecord.pickuparea = "-";
            }
            if (response.data.data.dropofarea) {
              this.reservationRecord.dropoffarea =
                response.data.data.dropofarea;
            } else {
              this.reservationRecord.dropoffarea = "-";
            }

            if (response.data.data.rentalduration) {
              this.reservationRecord.duration =
                response.data.data.rentalduration;
            } else {
              this.reservationRecord.duration = "-";
            }
            this.reservationRecord.reservationdate =
              DateFormat.formatDateReverse(response.data.data.reservationdate);
            this.reservationRecord.reservationtime =
              response.data.data.reservationtime;
            this.reservationRecord.paymentmethod =
              response.data.data.paymentmethod;
            this.reservationRecord.servicecharge =
              response.data.data.servicecharge;
            this.reservationRecord.receivedate =
              DateFormat.formatDateTimeReverse(
                response.data.data.receiveddate
              );
            this.reservationRecord.status = response.data.data.status;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            this.$emit("displayNo");
            this.display = false;
          }
        })
        .finally(() => {
          this.disableDropdownOptions();
        });
    },
    EditStatus() {
      let payload = {
        status: this.reservationRecord.status,
      };
      APIService.put("/reservation/statuschange", payload, this.recordId).then(
        (response) => {
          if (response && response.data && response.data.success) {
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: response.data.message,
              life: 3000,
            });
            this.updateSuccess();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        }
      );
    },
    openPopup() {
      this.display = true;
      this.status = [
        { label: "In Progress", value: "inprogress", sequence: 1 },
        { label: "Confirmed", value: "confirmed", sequence: 2 },
        { label: "Paid", value: "paid", sequence: 3 },
        { label: "Cancelled", value: "cancelled", sequence: 4 },
      ];
    },
    closePopup() {
      this.status = [];
      this.display = false;
      this.$emit("displayNo");
    },
    updateSuccess() {
      this.status = [];
      this.display = false;
      this.$emit("updateSuccess");
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep(.p-dropdown) {
  width: 220px;
}
</style>
