import DateFormat from "@/libs/DateFormat";

export default class ReservationRecord {
  constructor(data = {}) {
    return {
      id: data.id || "",
      reservationno: data.reservationno || "",
      username: data.username || "",
      reservationtype: data.reservationtype || "",
      carmodeltype: data.carmodeltype || "",
      status: data.status || "",
      createddate: DateFormat.formatDate(data.createddate) || "",
    };
  }
}
