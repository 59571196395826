import DateFormat from "@/libs/DateFormat";

export default class PaymentMethod {
  constructor(data = {}) {
    return {
      id: data.id || "",
      paymentmethod: data.paymentmethod || "",
      createddate: DateFormat.formatDate(data.createddate) || "",
    };
  }
}
